<template>
  <div class="activation-box">
    <div class="box">
      <div class="left">
        <span>激活码：</span>
        <input v-model="code" type="text" placeholder="请输入激活码">
      </div>
      <div class="right" @click="handleConfirm">
        确认激活
      </div>
    </div>
    <div class="rule-box">
      <p>活动规则：</p>
      <p>1.输入有效兑换码可以获得对应商品；</p>
      <p>2.一个码仅兑换一次，不可重复兑换；</p>
      <p>3.活动解释权归平台所有。</p>
    </div>

    <div class="page-bottom" @click="nextTo">
      查看兑换纪录
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { PullRefresh, Icon, Toast } from 'vant'
import {
  changeCode
} from '@/services/couponPage'

Vue.use(PullRefresh).use(Icon)
export default {
  data() {
    return {
      code: ''
    }
  },
  mounted() {
  },
  methods: {
    nextTo() {
      const mid = this.$route.query.mid
      if (mid != null) {
        this.$router.push({
          path: '/recordList?mid=' + mid
        })
      }
    },
    async handleConfirm() {
      const mid = this.$route.query.mid
      const res = await changeCode({
        code: this.code,
        mid: mid || ''
      })
      console.log(res)
      if (res.code === 200) {
        this.code = ''
        Toast({
          type: 'success',
          message: '兑换成功'
        })
      }
    }
  }
}
</script>
<style scoped>
.activation-box {
  background: #fff !important;
  width: 100%;
  height: 100vh;
  padding: 17px 11px;
}

.box {
  width: 100%;
  height: 75px;
  background: #eff1fe;
  border-radius: 10px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 15px;
  color: #333;
}

.box .left input {
  width: 163px;
  height: 26px;
  background: #ffffff;
  border-radius: 5px;

}

.box .right {
  color: #fff;
  font-size: 14px;
  width: 86px;
  line-height: 26px;
  text-align: center;
  background: #e44044;
  border-radius: 13px;
}

.rule-box {
  padding-top: 20px;
  color: #333;
  font-weight: W8-Proportional;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: left;
}

.rule-box p {
  padding-top: 5px;
}

.page-bottom {
  position: fixed;
  bottom: 72px;
  left: 56px;
  right: 56px;
  line-height: 44px;
  text-align: center;
  background: #e44044;
  border-radius: 22px;
  color: #fff;
}
</style>
